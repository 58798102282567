import React from "react"

const Footer = () => {
  return (
    <footer className="mt-16 bg-neutral-100 py-8 text-neutral-700">
      <div className="container">
        <a href="https://www.lightshopping.com/" target="_blank" class="text-xl font-medium">
          LightShopping.com
        </a>
      </div>
    </footer>
  )
}

export default Footer
