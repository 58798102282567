import React from "react"
import { Link } from "gatsby"

const Header = () => {
  return (
    <header className="bg-primary-200">
      <nav className="container flex flex-row items-baseline justify-between py-6">
        <Link to="/" className="text-xl font-medium">
          Blog
        </Link>
      </nav>
    </header>
  )
}

export default Header
